@font-face {
    font-family: Coves Bold;
    src: url('../Fonts/Coves\ Bold.otf');
}
@font-face {
    font-family: Coves Light;
    src: url('../Fonts/Coves\ Light.otf');
}
@font-face {
    font-family: Nunito Light;
    src: url('../Fonts/Nunito/Nunito-Light.ttf');
}
body{
    padding: 0;
    margin: 0%;
    font-family: Nunito Light;
}
h3{
    font-family: Coves Light;
}
.page-container{
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0%;
    background-color: #F7F9FD;
    overflow: hidden;
    display: flex;
    
}

/* Todo el contenido central */
.main-content{
    height: 92vh;
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.vales-view{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 95%;
    height: 95%;
    overflow: hidden;
    scrollbar-width: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
}
.view-title{
    height: 8vh;
    width: 100%;
    background-color: #24bf97;
    color: white;
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-title{
    margin-left: 2%;
    margin-right: 2%;
    text-transform: uppercase;

}

.show-history{
    font-size: 3vh;
}
.show-history:hover{
    font-size: 3vh;
    border: 2px solid white;
    cursor: pointer;
    border-radius: 5px;;
}

.page-real-content{
    height: 92%;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
.page-real-content::-webkit-scrollbar{
    display: none;
}

/* Componentes de la barra izquierda */
.leftbar{
    height: 100vh;
    width: 5vw;
    background-image: linear-gradient(180deg, #df4e88 10%, #224abe 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;
    font-size: 3vh;
}
.logo-left{
    width: 80%;
    height: 10vh;
    border-bottom: 1px solid rgba(245, 245, 245, 0.329);
}
.logo-left:hover{
    cursor: pointer;
}
.item-left{
    height: 4vh;
    display: flex;
    gap: 5%;
    align-items: center;
    width: 90%;
    margin-top: 2vh;
}
.item-left:hover{
    cursor: pointer;
}
.icons-left{
    height: 4vh;
}
.item-general-left{
    height: 4vh;
    font-size: large;
}

/* Componentes de la barra superior */
.topbar{
    height: 8vh;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    color: #8C8E9C;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.topbar .topbar-items{
    display: flex;
    flex-direction: row;
    width: 40%;
}
.h2-left{
    border-right: 2px solid #8C8E9C;   
    margin-right: 3vh;
    width: 5vh; 
}
.item-topbar{
    height: 3vh;
    font-size: 2vh;

}
.item-icon{
    height: 100%;
}
.items-right{
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    gap: 4%;
    align-items: center;
}

@media screen and (orientation: portrait) {
    .leftbar{
        width: 9vw;
    }
    .main-content{
        width: 91vw;
    }
    .topbar{
        height: 6vh;
    }
    .logo-left{
        height: 3vh;
        padding: 1vh;
    }
    .item-topbar{
        font-size: 1.5vh;
        height: 1.5vh;
    }
    .page-title{
        font-size: 2vh;
    }
}
.home-button{
    background-color: #2e59d9b7;
    color: white;
    border: 1px solid #2653d4;
    border-radius: 5px;
    height: 4vh;
    width: 6vw;
    font-size: 2vh;
}
.home-button:hover{
    cursor: pointer;
    background-color: #2e59d9;
}