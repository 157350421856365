.token-modal{
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.division-vales-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 5vh;
}
.data-target-view{
    width: 40vw; 
}

.division-vales{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.vales-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    align-items: center;
}
.vales-left::-webkit-scrollbar{
    display: none;
}

.little-title{
    color: rgba(0, 0, 0, 0.486);
    margin-top: 2vh;
    font-size: 3vh;
}
.file-loader{
    border-bottom: 1px solid palevioletred;
    width: 92%;
    display: flex;
    flex-direction: row;
    gap: 10%;
    padding-bottom: 5px;
    padding-top: 5px;
}
.file-selector{
    width: 40%;
}
.file-loader-text{
    width: 50%;
}
.button-container{
    height: 8vh;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5vh;
}
.upload-button{
    height: 30px;
    width: 8vw;
    background-color: #2c9faf;
    border: 1px solid #2a96a5;
    color: white;
    font-size: 16px;
    border-radius: 5px;
}
.upload-button:hover{
    color: white;
    cursor: pointer;
}
.add-vale{
    color: white;
    height: 30px;
    width: 100px;
    background-color: #24bf97;
    border: 1px solid #169b6b;
    border-radius: 5px;
}
.add-vale:hover{
    cursor: pointer;
}
.more-button-container{
    height: 5vh;
    width: 5vh;
}
.more-button{
    height: 80%;
}
/* Todo lo de la derecha */
.vales-right{
    border-left: 1px solid rgba(128, 128, 128, 0.247);
    width: 50%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.data-container{
    width: 90%;
    height: 90%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 3%;
    justify-content: center;
    padding: 2%;
    overflow: scroll;
    scrollbar-width: none;
}
.data-container::-webkit-scrollbar{
    display: none;
}
.data-target{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    background-color: tomato;
}

@media screen and (orientation:portrait){
    .division-vales{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .vales-left{
        width: 100%;
    }
    .vales-right{
        width: 100%;
        height: 40vh;
    }
    .upload-button{
        width: 250px;
        height: 40px;
        font-size: 25px;
    }
    .little-title{
        font-size: 2vh;
    }
    .file-loader{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 3px solid red;
    }
    .file-selector{
        width: 50%;
    }
    .add-vale{
        color: #23BF97;
        height: 30px;
        width: 100px;
        border: 2px solid #23BF97;
    }
}