.modal-menu{
    height: 20vh;
    width: 20vw;
    border: 2px solid purple;
    background-color: white;
    position: absolute;
    top: 40vh;
    left: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modal-menu h1 {
    color: rgba(0, 0, 0, 0.486);
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}
.modal-menu button {
    background-color: transparent;
    font-size: 15px;
    border: 2px solid #23BF97;
    color: #23BF97;
    border-radius: 3px;
}
.modal-menu button:hover {
    background-color:#23BF97;
    cursor: pointer;
    color: white;
}
.modal-menu p {
    text-align: center;
}

@media screen and (orientation: portrait) {
    .modal-menu{
        height: 30vw;
        width: 30vh;
        top: 35vh;
        left: 35vw;
    }
}

