.modal-history{
    background-color: rgba(247, 247, 247, 0.733);
    height: 90vh;
    width: 80vw;
    position: absolute;
    border: 2px solid #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    scrollbar-width: none;
    border-radius: 20px;
}
.history-top{
    height: 8vh;
    background-color: rgb(145, 35, 126);
    color: azure;
    font-size: 5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.history-top-pt{
    height: 8vh;
    background-color: rgb(41, 180, 169);
    color: azure;
    font-size: 5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.history-title{
    width: 30vw;
    height: 6vh;
}
.button-history{
    background-color: #115f4b ;
    height: 6vh;
    width: 10vw;
    font-size: 4vh;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}
.button-history:hover{
    background-color: #25af8d ;
}
.table-container{
    height: 82vh;
    overflow: scroll;
    scrollbar-width: none;
}